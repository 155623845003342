<template>
  <div class="register">
    <div class="top-pic">
      <img src="@/assets/img/loginRegister/logo.png" alt="" />
    </div>
    <div class="holder">
      <div class="box">
        <div class="icon-input flex align-center">
          <img src="@/assets/img/loginRegister/phone.png" alt="" />
          <p>手机号<span>（注：1个微信只能绑定1个固定手机号）</span></p>
        </div>
        <input
          v-model.trim="phone"
          type="tel"
          placeholder="请输入手机号"
          maxlength="11"
        />
      </div>
      <div class="box">
        <div class="icon-input flex align-center">
          <img src="@/assets/img/loginRegister/sms.png" alt="" />
          <p>验证码</p>
        </div>
        <input
          v-model.trim="smsCode"
          type="tel"
          placeholder="请输入验证码"
          maxlength="6"
        />
        <p v-show="timeShow" class="code" @click="getCode">获取验证码</p>
        <p v-show="!timeShow" class="count">{{ count }}s</p>
      </div>
      <div class="box">
        <div class="icon-input flex align-center">
          <img src="@/assets/img/loginRegister/invite.png" alt="" />
          <p>邀请码</p>
        </div>
        <input
          v-model.trim="inviteCode"
          :readonly="$route.query.inviteCode ? true : false"
          type="text"
          placeholder="请输入邀请码，非必填"
          maxlength="6"
          class="three-input"
        />
      </div>
      <p class="to-login">已有账号，<span @click="toLogin">立即登录</span></p>
      <p
        :class="[
          'next',
          {
            'next-sure':
              (this.phone || this.phone === 0) &&
              (this.smsCode || this.smsCode === 0) &&
              isAgree,
          },
        ]"
        @click="nextFun"
      >
        下一步
      </p>
      <div class="agree flex flex-center align-center">
        <div class="select" @click="selectFun">
          <img
            v-show="!isAgree"
            src="@/assets/img/loginRegister/no-select.png"
            alt=""
          />
          <img
            v-show="isAgree"
            src="@/assets/img/loginRegister/select.png"
            alt=""
          />
        </div>
        <p>同意<span @click="agreeContent">《用户服务协议》</span>、<span @click="privacyContent">《隐私政策》</span></p>
      </div>
    </div>
  </div>
  <toast-box
    v-if="showToast"
    :show-toast="showToast"
    :msg="msg"
    @sure="handleSure"
  ></toast-box>
</template>
<script>
import { getverifycode } from "@/utils/loginRegister.js";
import ToastBox from "../../components/toast.vue";
export default {
  name: "Register",
  components: {
    ToastBox,
  },
  data() {
    const query = this.$route.query
    return {
      inviteCode: query.inviteCode || "",
      msg: "",
      count: "",
      timer: null,
      timeShow: true,
      phone: "",
      smsCode: "",
      showToast: false,
      isAgree: false,
    };
  },
  methods: {
    toLogin() {
      this.$router.push("/login");
    },
    selectFun() {
      if (this.isAgree) {
        this.isAgree = false;
      } else {
        this.isAgree = true;
      }
    },
    handleSure(val) {
      this.showToast = val;
    },
    getCode() {
      if (!this.phone && this.phone !== 0) {
        return this.$toast("请输入手机号");
      } else if (!/^1\d{10}$/.test(this.phone)) {
        this.showToast = true;
        this.msg = "手机号填写错误<br>请填写11位正确的手机号";
        return;
      } else {
        getverifycode({
          phone: this.phone,
        }).then((res) => {
          if (res.code == "0") {
            this.$toast.success("验证码已发送");
            this.code();
          } else {
            this.$toast.fail(res.message);
          }
        });
      }
    },
    //获取验证码倒计时
    code() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.timeShow = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.timeShow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    agreeContent() {
      window.location.href = "/agreement/service.html"
    },
    privacyContent() {
       window.location.href = "/agreement/privacy.html"
    },
    nextFun() {
      if (!this.phone && this.phone !== 0) {
        return;
      } else if (!/^1\d{10}$/.test(this.phone)) {
        this.showToast = true;
        this.msg = "手机号填写错误<br>请填写11位正确的手机号";
        return;
      } else if (!this.smsCode && this.smsCode !== 0) {
        return;
      } else if (!/^\d{6}$/.test(this.smsCode)) {
        this.showToast = true;
        this.msg = "验证码填写错误<br>请重新填写正确的验证码";
        return;
      } else if (!this.isAgree) {
        this.showToast = true;
        this.msg = "需要查看并同意用户协议后<br>才可注册";
        return;
      }
      if (this.inviteCode && !/^[0-9a-zA-Z]{6}$/.test(this.inviteCode)) {
        this.msg = "邀请码的格式不正确<br>请输入6位数字、英文字母的<br>邀请码";
        this.showToast = true;
        return
      }
      sessionStorage.setItem(
        "registerInfo",
        JSON.stringify({
          phone: this.phone,
          smsCode: this.smsCode,
          inviteCode: this.inviteCode
        })
      );
      this.$router.push({
        path: "/setPassword",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.register {
  height: 100vh;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
  .top-pic {
    height: 161px;
    text-align: center;
    img {
      width: 120px;
      height: 120px;
      margin-top: 35px;
    }
  }
  .holder {
    .box {
      width: 315px;
      border-bottom: solid 1px #f6f6f6;
      padding: 30px 0 15px;
      margin: 0 auto;
      text-align: left;
      position: relative;
      .icon-input {
        height: 18px;
        img {
          width: 16px;
          height: 16px;
        }
        p {
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-left: 5px;
          span{
            color: #999999;
            font-size: 12px;
          }
        }
      }
      .code {
        position: absolute;
        bottom: 14px;
        right: 0;
        width: 84px;
        height: 28px;
        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        -ms-border-radius: 14px;
        -o-border-radius: 14px;
        border-radius: 14px;
        border: 1px solid #e62129;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #e62129;
        line-height: 28px;
        text-align: center;
      }
      .count {
        position: absolute;
        bottom: 14px;
        right: 0;
        width: 84px;
        height: 28px;
        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        -ms-border-radius: 14px;
        -o-border-radius: 14px;
        border-radius: 14px;
        border: 1px solid #cccccc;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        text-align: center;
        line-height: 28px;
      }
    }
    input {
      height: 24px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      margin-top: 15px;
    }
    .three-input{
      width: 250px;
    }
    input::-webkit-input-placeholder {
      color: #cccccc;
    }
    .to-login {
      width: 315px;
      height: 18px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
      margin: 15px auto 30px;
      text-align: right;
      span {
        color: #0074d7;
      }
    }
    .next {
      width: 315px;
      height: 44px;
      background: #e62129;
      -webkit-border-radius: 22px;
      -moz-border-radius: 22px;
      -ms-border-radius: 22px;
      -o-border-radius: 22px;
      border-radius: 22px;
      opacity: 0.5;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      line-height: 44px;
      margin: 0 auto;
    }
    .next-sure {
      opacity: 1;
    }
    .agree {
      height: 22px;
      margin-top: 15px;
      .select {
        height: 14px;
        padding-right: 10px;
        img {
          width: 14px;
          height: 100%;
        }
      }
      p {
        height: 22px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #60656e;
        line-height: 22px;
        span {
          color: #4096fc;
        }
      }
    }
  }
}
</style>